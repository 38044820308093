.toolbarContainer {
	display: flex;
	flex-direction: column;
	padding: 16px;
}

.advancedSearch {
	position: relative;
	display: flex;
	width: 100%;
	padding: 32px;
	border: 2px solid #002b64;
	border-radius: 15px;
}

.advancedSearchFields {
	position: relative;
	display: grid;
	justify-content: space-evenly;
	grid-template-columns: repeat(5, 1fr);
	gap: 10px;
	width: 100%;
	padding-right: 50px;
}

.advancedSearchFields label {
	transform: translate(14px, 12px) scale(1);
}

.advancedClose {
	position: absolute;
	top: 0;
	right: 0;
}

.exportIcon {
	margin-left: auto !important;
}
